html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/*
 disable RESET filter button - DEV
*/
div[class^="MUIDataTableFilter-reset-"] {
  display: none;
}

/*
  disable RESET filter button - PROD

*/
div[class^="jss147"] > button {
  display: none;
}
